.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
  width: 80%;
  height: 50%;
  min-height: 35vh;
  padding: 1em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .container {
    padding: 1em;
    margin-top: 1em;
  }
}
