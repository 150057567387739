.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  button {
    border-radius: 3.125rem;
    border: 1px solid var(--color-bg-lightGrey);
    padding: 0.5em 1em;
    margin-bottom: 1em;
    font-size: 1em;
    cursor: pointer;
    min-width: 85%;
    max-width: 85%;
    width: auto;
    background-color: transparent;
    text-align: left;
  }

  button:active,
  button:focus,
  button:focus-visible {
    outline-color: var(--color-MR-purple);
  }

  .option-selected {
    border: 1px solid transparent;
    background-color: var(--color-MR-purple);
    color: white;
    font-size: 1em;
    font-weight: 700;
  }

  .option-clicked {
    animation: bounce 0.4s ease;
    background-color: var(--color-MR-purple);
    border: 1px solid transparent;
    color: white;
    font-size: 1.125em;
    font-weight: 700;
  }
}

.answerText {
  transition: opacity 0.2s ease-out;
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}


// Non-mobile styles
@media (min-width: 769px) {
  .container {
    .option:hover {
      border: 1px solid purple;
      color: #000;
      // font-weight: 400;
    }

    .option-selected:hover {
      color: white;
    }
  }
}

@media (max-width: 768px) {
  .container {
    height: 100%;

    button {
      padding: 1em;
      margin-bottom: 1em;
      min-width: 80%;
      max-width: 95%;
      text-align: center;
    }
  }
}