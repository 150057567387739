#foundation {
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    position: absolute;
    bottom: -30em;
    width: 40em;
    right: -10em;
    z-index: 0;
    transform: rotate(10deg);
  }
}
