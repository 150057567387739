.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  padding: 8em 0;

  h2 {
    color: var(--color-MR-purple);
    border-bottom: 2px solid var(--color-MR-pink);
    width: fit-content;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 2em;
    /* Reduced bottom margin for better spacing */
    line-height: 1.4;
  }


  .formBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    width: 40vw;
    min-height: 30vh;
    padding: 2em;
    border-radius: 0.5rem;
    background: #FAFAFB;
    box-shadow: 0px 3px 14px -3px var(--color-shadow-black-1);

    form {
      display: flex;
      flex-direction: column;
      width: 60%;
      max-width: 600px;
    }
  }

  input[type='email'],
  button {
    font-size: 1rem;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 0.5em;
  }

  input[type='email'] {
    border: 1px solid rgba(0, 0, 0, 0.10);
  }

  input:focus {
    outline: none;
    border: 1px solid var(--color-MR-purple);
  }

  button {
    font-size: 1rem;
    background-color: var(--color-MR-purple);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--color-MR-blue);

    }
  }

  label {
    align-self: flex-start;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .thankYouMessage {
    width: 100%;
    text-align: center;
    color: var(--color-MR-blue);
  }
}

/* Media query for very small devices */
@media (max-width: 768px) {
  .container {
    text-align: center;

    p {
      width: 80%;
    }

    .formBox {
      min-width: 70%;

      form {
        width: 100%;
      }
    }

  }
}