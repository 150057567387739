#foundation {
  width: 60em;
  margin-bottom: 4em;
  h3 {
    font-size: 1.5em;
    color: var(--color-MR-purple);
    border-bottom: 2px solid var(--color-MR-pink);
    width: fit-content;
    margin-bottom: 0.5em;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;
    column-gap: 1em;
    font-size: 1.125em;
    .boxs {
      display: flex;
      align-items: center;
      width: 25em;
      height: fit-content;
      img {
        width: 1em;
        padding: 0;
        margin-right: 0.5em;
        margin-top: 0.15em;
        margin-bottom: auto;
        transition:
          margin-top 0.2s,
          width 0.2s;
      }
      div {
        height: fit-content;
        font-weight: 700;
        width: 90%;
        color: var(--color-MR-blue);
        transition: font-weight 0.2s;
      }
    }
  }
}
@media (max-width: 970px) {
  #foundation {
    width: fit-content;
    margin-left: 6%;
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 90%;
    margin: 0;
    padding-bottom: 3em;
    h3{
      font-size: 1.2em;
    }
  }
}
