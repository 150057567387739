.foundation {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5em 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  z-index: 3;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    #box1 {
      margin: 1em;
      width: 18em;
      >img{
        width: 10.5em;
      }
      > div {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        > .box1Links {
          text-decoration: none;
          color: var(--color-bg-grey);
          width: fit-content;
          transition: color 0.3s ease;
          margin-bottom: .5em;
        }
        > .box1Links:hover {
          color: var(--color-bg-black);
        }
      }
    }

    #box2 {
      margin: 1em;
      text-align: center;
      #box2heading {
        margin: 0;
        font-weight: 500;
        font-family: 'Azo Sans';
        text-align: center;
        font-size: 1.4em;
      }
      #box2description {
        padding: 1em 0;
        margin: 0;
        text-align: center;
        font-size: 1.2em;
      }
    }
    #box3 {
      margin: 1em;
      margin-right: 0;
      font-weight: 700;
      width: 18em;
      display: flex;
      flex-direction: column;
      align-items: center;
      #box3heading {
        margin-top: 0;
        margin-bottom: .5em;
        width: fit-content;
        align-self: flex-start;
      }
      .box3text {
        margin: 0;
        margin-bottom: .5em;
        a{
        text-decoration: none;
        color: black;

        }
      }
      > a {
        color: var(--color-MR-blue);
        margin-top: 0;
        padding: 0;
        border: solid red;
        margin-left: 2em;
        display: contents;
      }
      .box3P {
        margin-top: 2.5em;
        margin-bottom: 1em;
        align-self: flex-start;
      }
      .box3lastAtag {
        margin-top: 0;
        align-self: center;
      }
      img {
        width: 10em;
        margin-bottom: .5em;
        align-self: center;
      }
    }
  }

  p.footerLine {
    a {
      text-decoration: none;
      color: var(--color-bg-grey);
    }
    #footNavBr {
      display: none;
    }
  }

  #footNavHr {
    display: none;
    background-color: var(--color-MR-blue);
    width: calc(100% - 1em);
    height: 2px;
  }
}

@media (max-width: 800px) {
  .foundation {
    height: fit-content;
    padding: 1em;
  }

  nav {
    align-items: start;

    > p {
      align-self: center;
      text-align: center;
    }

    .container {
      flex-direction: column;
      align-items: center;
      width: 100%;
      #box1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        > img {
          width: 10em;
          margin-bottom: 1em;
        }
        > div {
          flex-direction: column;
          align-items: center;
        }
      }
      #box2 {
        text-align: left;
        margin: 4em 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        #box2description {
          margin-bottom: 1em;
        }
      }
      #box3 {
        margin-bottom: 3em;
        align-items: center;
        .box3P {
          margin-top: 4em;
        }
        a {
          padding: 0;
          margin-top: .5em;
        }
      }
    }
    #footNavHr {
      display: block;
    }
    p.footerLine {
      #footNavBr {
        display: block;
      }
    }
  }
}
