.containerWrapper {
  height: 100%;
  width: 80vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  padding: 4px; // Border
  background: var(--gradient-purplePinkBlue);
  border-radius: 0.5rem;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  min-height: 80vh;
  background: white;
  border-radius: calc(
    0.5rem - 4px
  ); //Reduce border-radius to fit within wrapper

  h2 {
    margin: 1em 0 0.6em 0;
    color: var(--color-MR-purple);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3.otherRoles {
    margin-top: 1em;
    width: 90%;
    color: var(--color-MR-purple);

    a {
      color: var(--color-MR-blue);
    }
  }

  h4 {
    width: 90%;
    font-size: 1em;
    margin-top: 0.3em;
    margin-bottom: 2em;

    a {
      color: var(--color-MR-blue);
      font-weight: 700;
    }
  }

  h3,
  h4 {
    padding-left: 2.5rem;
  }

  p {
    font-size: 1.25em;
    width: 90%;
  }

  .roleCards {
    display: flex;
    margin: 2em;
    column-gap: 1em;
  }

  .exploreLink {
    text-decoration: none;
    height: 4em;
    display: flex;
    align-items: center;
    margin: 2em 1em;
    text-align: center;
    #exploreImg {
      width: 2em;
      transition: width 0.3s;
      height: fit-content;
    }
  }
  .exploreLink:hover {
    #exploreImg {
      width: 1.5em;
    }
  }
}

@media (max-width: 768px) {
  .containerWrapper {
    width: auto;
  }

  .container {
    padding: 0;
    text-align: center;

    h2 {
      width: 80%;
      text-align: center;
      font-size: 1.7em;
    }

    h3 {
      padding: 0;
      width: 80%;
      text-align: center;
      &.otherRoles {
        margin-bottom: 1em;
      }
    }

    .roleCards {
      flex-direction: column;
      row-gap: 2em;
      margin: 1em;
      align-items: center;
    }
    .exploreLink {
      justify-content: center;
    }
  }
}
