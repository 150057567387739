.container {
  // border: solid green;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}
