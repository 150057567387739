.containerWrapper {
  min-width: 35vw;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 4px; // Border
  background: var(--gradient-purplePinkBlue);
  border-radius: 0.5rem;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 100%;
  min-height: 5vh;
  width: 100%;
  background: white;
  border-radius: calc(
    0.5rem - 4px
  ); //Reduce border-radius to fit within wrapper

  h2 {
    font-size: 1.5em;
    font-weight: 500;
    color: var(--color-MR-purple);
  }

  .sectionText {
    padding: 0.8em 2em;
    min-width: 20vw;
  }
}

.fadeOut {
  opacity: 0;
}

.titleText {
  font-weight: 400;
  font-size: 1em;
}

.subTitleText {
  font-weight: 400;
  font-size: 1em;
}

@media (max-width: 768px) {
  .containerWrapper {
    width: 100%;
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
