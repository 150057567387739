.container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--color-bg-offWhite);
  z-index: 1;
  padding: 3em 0;
  margin-top: -3em;

  >div {
    padding: 3em;
  }

  .hero {
    display: flex;
    max-width: 1200px;
    width: 80vw;

    .innerContainer {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        font-size: 2.4em;
        font-weight: 500;
        margin-bottom: .5em;
      }

      p {
        margin-right: 1em;
        font-size: 1.2em;
        margin-bottom: 3em;
        line-height: 1.4;
      }
    }
  }

  /* Responsive image styling to maintain aspect ratio */
  .schoolStudents {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

/* Media query for moderately small devices */
@media (max-width: 1060px) {
  .container {
    height: fit-content;

    .hero {
      .innerContainer h2 {
        font-size: 2em;
      }
    }
  }
}

/* Media query for small devices, typical for mobile views */
@media (max-width: 950px) {
  .container {
    padding: 0;

    >img {
      display: none;
    }
  }
}

/* Media query for very small devices */
@media (max-width: 768px) {
  .container {

    .hero {
      flex-direction: column;

      .innerContainer {
        margin-top: 1em;
        width: 100%;

        h2 {
          font-size: 2em;
        }
      }

      p {
        font-size: 1em;
      }
    }

    img {
      height: 30vw;
    }
  }
}