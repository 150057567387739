#foundation {
  padding-bottom: 4em;
  width: 60em;
  #mobileHeader {
    display: none;
  }
  img {
    width: 90%;
    height: 25em;
    margin-top: 4em;
    padding: 0;
    margin-left: 10%;
    border-radius: 12px;
  }
  .container {
    width: 100%;
    a {
      color: black;
    }
    .innerContainer1 {
      #pcHeader {
        color: var(--color-MR-purple);
        margin-top: 2.5em;
        margin-bottom: 1em;
        font-size: 2.25em;
        width: fit-content;
        border-bottom: 2px solid var(--color-MR-pink);
      }
    }
    .innerContainer2 {
      h3 {
        color: var(--color-MR-purple);
        margin-top: 1em;
        font-size: 1.5em;
        width: fit-content;
        border-bottom: 2px solid var(--color-MR-pink);
        margin-bottom: 0.5em;
      }
      .inner2BoxContainers {
        display: flex;
        align-items: center;
        height: fit-content;
        font-weight: 500;
        color: var(--black-color-bg);
        margin-bottom: 0.5em;
        img {
          width: 2em;
          height: fit-content;
          padding: 0;
          margin: 0;
          transition: width 0.3s;
          align-self: start;
        }
        div {
          font-size: 1.1em;
          transition: font-weight 0.1s;
        }
      }
    }
  }
}
@media (max-width: 970px) {
  #foundation {
    width: fit-content;
  }
}
@media (max-width: 830px) {
  #foundation {
    img {
      align-self: flex-start;
    }
  }
}
@media (max-width: 768px) {
  #foundation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
    img {
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 0;
    }
    #mobileHeader {
      display: block;
      color: var(--color-MR-purple);
      margin-top: 1.5em;
      font-size: 2.25em;
      width: fit-content;
      border-bottom: 2px solid var(--color-MR-pink);
      margin-bottom: 1em;
    }
    .container {
      .innerContainer1 {
        #pcHeader {
          display: none;
          font-size: 1.5em;
        }
      }
      .innerContainer2 {
        h3 {
          font-size: 1.2em;
        }
        .inner2BoxContainers {
          img {
            width: 2.5em;
          }
          div {
            font-size: 0.9em;
          }
          .inner2BoxContainers:hover img {
            width: 1.8em;
          }
        }
      }
    }
  }
}
