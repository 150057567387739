.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 35em;
  width: 100%;
  max-width: 20em;
  padding: 1.5em;
  border-radius: 0.5rem;
  background: #fff;
  filter: drop-shadow(0px 3px 5px var(--color-shadow-black-1));

  h2 {
    color: var(--color-MR-purple);
    border-bottom: 3px solid var(--color-MR-pink);
    width: fit-content;
    font-size: 2.5em;
    font-weight: 400;
  }

  p {
    font-weight: bold;
    font-size: 1.2em;

    &.description {
      font-size: 16px;
      font-weight: 400;
    }
  }

  div {
    color: var(--color-MR-blue);
    border-bottom: 2px solid var(--color-MR-pink);
    width: fit-content;
    font-size: 1.5em;
    font-weight: 600;
    text-decoration: none;

    &.title {
      font-family: 'Azo Sans';
      font-weight: 700;
    }
  }

  .roleImage {
    height: 125px;
    margin: 2em;
  }

  .values {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1em;
    row-gap: 0.2em;
    height: fit-content;
    padding-bottom: 1em;

    div {
      display: flex;
      align-items: center;
      border-bottom: none;
      font-size: 0.8em;
      min-width: 7em;
      color: var(--color-bg-grey);

      img {
        width: 1.2rem;
        margin-right: 0.3em;
      }
    }
  }
  .values:hover {
    cursor: default;
  }

  .learnMore {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
    margin-top: auto;
    height: 3em;

    > div {
      color: var(--color-MR-purple);
      transition: color 0.3s;
      border-bottom: none;
      font-family: 'Azo Sans';
      font-weight: 500;
      font-size: 1.5em;
    }

    .learnmoreImg {
      transition: width 0.2s;
      width: 3em;
      height: fit-content;
      margin-left: 2em;
    }
  }
  .learnMore:hover {
    div {
      cursor: pointer;
      color: var(--color-text-hover-dark-violet);
    }
    .learnmoreImg {
      width: 2.5em;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .container {
    box-sizing: border-box;
    padding:  1em 2em;
    max-width: none;
    width: auto;
    margin: 0 1em;

    .values {
      flex-direction: column;
      align-self: center;
    }

    .roleImage {
      margin: 1.5em;
      max-width: 40%;
    }

    .learnMore {
      justify-content: space-between;
      column-gap: 1.5em;
      width: 100%;
      > .learnmoreImg{
        margin: 0;
      }
    }

    p {
      align-self: flex-start;
    }
  }
}
