.container {
  .content {
    background: var(--gradient-404background);
    color: #fff;
    min-height: 100vh;
    padding: 0 4em;
  }
  .content > h1 {
    color: #fff;
    font-size: 3em;
  }
  .content > p {
    font-size: 1.5em;
  }
  #technus404img {
    position: relative;
    width: 10em;
    height: 3.8em;
    margin-top: 15em;
    margin-bottom: 2em;
  }
  #RocketPhoto {
    position: absolute;
    background: url('../../../public/Rocket.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  @media (max-width: 1000px) {
    #RocketPhoto {
      position: relative;
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
      width: 30em;
      height: 30em;
    }
    .content > h1 {
      font-size: 5em;
    }
    .content > p {
      font-size: 2em;
    }
  }
  @media (max-width: 600px) {
    .content {
      padding: 0;
    }
    #technus404img {
      margin-top: 5em;
      margin-left: 1em;
    }
    #RocketPhoto {
      width: 100%;
      height: 35em;
    }
    .content > h1 {
      font-size: 2em;
      margin-left: 0.5em;
    }
    .content > p {
      font-size: 1em;
      margin-bottom: 2em;
      margin-left: 1em;
    }
  }
  @media (min-width: 1000px) {
    #RocketPhoto {
      width: 40em;
      height: 40em;
    }
  }
}
