.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
  width: 80%;
  min-height: 5vh;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
}

.questionText {
  margin-left: 1em;
  transition: opacity 0.2s ease-out;
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}
