.container {
  display: flex;
  align-items: center;
  padding: 0.5em 1vmax 0.5em 1vmax;
  height: 2em;
  background-color: var(--color-bg-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 100;

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    width: 100%;
    max-width: 1400px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2em;

    .techniusLogo {
      width: 6em;
    }

    .navLink {
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: var(--color-white);
      text-decoration: none;
      z-index: 1;
      padding: 0 2em;
      span {
        text-transform: uppercase;
      }
    }
    .navLink:hover{
      cursor: pointer;
      background-color: var(--color-MR-purple);
      color: var(--color-bg-white);
    }
  }

  .icons {
    display: flex;
  }

  #NavBackground {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vw;
  }

  #selectForTechRolesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    z-index: 2;
  }

  #selectForTechRoles {
    border: none;
    background-color: var(--color-bg-white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 5;
    height: 3em;
    padding-right: 1em;
    padding-left: 2em;
    color: var(--color-bg-black);
    text-decoration: none;
  }

  #selectForTechRoles:hover {
    cursor: pointer;
    background-color: var(--color-MR-purple);
    color: var(--color-bg-white);
  }

  #selectForTechRoles > div:hover {
    cursor: pointer;
  }

  .selectForTechRolesOptionsContainer {
    position: absolute;
    box-shadow: 0 0 10px var(--gradient-navSelectShadow-grey);
    background-color: #fff;
    transition:
      top 0.3s ease,
      opacity 0.2s ease;
    width: 12em;
    opacity: 0;
    pointer-events: none;
  }

  #selectForTechRolesOptionsOpened {
    margin-top: 3em;
    top: 0;
    opacity: 1;
    pointer-events: auto;
    border-top: 1px solid #000;
  }

  #selectForTechRolesOptionsClosed {
    top: -20em;
    opacity: 0;
  }

  .selectForTechRolesOptions {
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--color-MR-navy);
    padding: 0.6em 0em;
    border-bottom: 1px solid var(--color-navSelectBorder-grey);
    transition:
      background-color 0.3s,
      color 0.5s;
  }

  .selectForTechRolesOptions:hover {
    background-color: var(--color-MR-purple);
    color: var(--color-bg-white);
    cursor: pointer;
  }

  .selectForTechRolesOptions > div {
    margin-left: 1em;
  }

  .selectForTechRolesOptions > div:hover {
    cursor: pointer;
  }

  // =============\/Mobile Navagation\/==========

  #MobileNavigation {
    display: none;
    padding: 0em;
    padding-right: 1em;
  }

  #techniusSymbol {
    width: 2.5em;
  }

  #closeHamburgerMenu:hover,
  #hamburgerMenu:hover {
    cursor: pointer;
  }

  .mobileNavOptionsContainers {
    position: absolute;
    top: 3em;
    background-color: var(--color-MR-purple);
    width: 80vw;
    height: 712px;
    display: flex;
    justify-content: center;
    transition: left 0.3s ease;
    /* Use the same property for transition */
  }

  #mobileNavOptionsContainer1 {
    left: 0;
  }

  #mobileNavOptionsContainer2 {
    left: -50em;
    /* Initially position it off-screen to the left */
  }

  #mobileNavOptionsInnerContainer {
    width: 80%;
    height: fit-content;
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    color: var(--color-bg-white);
  }

  #mobileNavOptionsBox {
    display: flex;
    flex-direction: column;
  }

  .mobileNavOptions {
    margin-left: 1em;
    text-decoration: none;
    color: var(--mission-ready-white);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3em;
    transition: background-color 0.3s;
  }

  // .mobileNavOptions:hover {
  //   background-color: var(--color-text-hover-dark-violet);
  // }

  .mobileNavOptions > div:hover,
  .mobileNavOptions > img:hover {
    cursor: pointer;
  }

  #mobileNavOptionTakeQuiz {
    text-decoration: none;
    color: var(--mission-ready-white);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    margin-left: 0;
    padding: 0;
  }

  #mobileNavOptionExploreRoles {
    margin-top: 2em;
    text-decoration: none;
    color: var(--mission-ready-white);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 0;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .icons {
      span {
        display: none;
      }
    }

    nav .techniusLogo {
      width: 10vh;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    #MainNavigation {
      display: none;
    }

    #MobileNavigation {
      display: flex;
    }
  }
}
