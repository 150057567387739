#foundation {
  width: 25em;
  height: 3em;
  margin-top: 1em;
  .container {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: background-color 0.3s;
    padding: 0;
    > h3 {
      color: var(--color-MR-purple);
      font-size: 1.5em;
      width: fit-content;
      font-weight: 500;
      transition: font-weight 0.2s;
    }
    > img {
      width: 3em;
      transition: width 0.2s;
    }
  }
  .container:hover {
    cursor: pointer;
  }
  .container:hover h2 {
    font-weight: 600;
  }
  .container:hover img {
    width: 2.5em;
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 100%;
    .container {
      > h3 {
        font-size: 1.2em;
      }
      > img {
        width: 2em;
      }
    }
    .container:hover img {
      width: 1.8em;
    }
  }
}
