.container {
  display: flex;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
  }
}

@media (max-width: 768px) {
  .container {
    margin-left: 0.5em;
  }
}
