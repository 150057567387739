.containerWrapper {
  height: 100%;
  width: 45vw;
  padding: 4px; // Border
  background: var(--gradient-purplePinkBlue);
  border-radius: 0.5rem;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 65vh;
  width: 100%;
  min-width: 20vw;
  background: white;
  border-radius: calc(
    0.5rem - 4px
  ); //Reduce border-radius to fit within wrapper

  .bottomQuizNav {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .quizIntro {
    display: flex;
    height: 100%;
    min-height: 65vh;
    padding: 1em;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .takeButton {
      width: 20em;
    }
  }
}

@media (max-width: 768px) {
  .containerWrapper {
    width: 100%;
  }

  .container {
    .quizIntro {
      height: 100%;
    }
  }
}
