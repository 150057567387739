.container {
  position: relative;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 3vh;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/binary-pattern.png');
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.2;
    z-index: -1;
  }

  &.homePage::before {
    display: none;
  }

  &.quizPage::before {
    height: 100%; // Adjusted height when footer is hidden
  }

  main {
    min-height: calc(100vh + 4em);
    height: 100%;
    margin: 2em 0;
  }

  @media (max-width: 768px) {
    &::before {
      background-size: auto;
      background-repeat: repeat-y;
    }
  }
}
