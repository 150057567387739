.container1 {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.container2 {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
@media (max-width: 768px) {
  .container1 {
    flex-wrap: wrap;
  }
  .container2 {
    flex-wrap: wrap-reverse;
  }
}
