#foundation {
  background-color: #fff;
  border-radius: 12px;
  padding: 3em 3em;
  width: 70em;
  margin: 4em 0;
  box-shadow: 2px 1px 3px 3px var(--gradient-fadedRose);
  .container {
    img {
      width: 30em;
      margin-left: auto;
      border-radius: 12px;
    }
    h2 {
      font-size: 2.25em;
      color: var(--color-MR-purple);
      border-bottom: 2px solid var(--color-MR-pink);
      width: fit-content;
      margin-bottom: 1em;
    }
    #mobileHeader {
      display: none;
    }
    .innerContainer1 {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      p{
        margin-top: 0;
      }
    }
  }
}
@media (max-width: 1160px) {
  #foundation {
    width: 60em;
  }
}
@media (max-width: 1000px) {
  #foundation {
    width: auto;
    margin: 3em;
    .container {
      img {
        width: 90%;
        height: 70%;
      }
    }
  }
}
@media (max-width: 768px) {
  #foundation {
    margin: 2em 4%;
    padding: 2em 5%;
    .container {
      #mobileHeader {
        display: block;
      }
      #pcHeader {
        display: none;
      }
      img {
        width: 100%;
        height: fit-content;
        margin: 0;
        margin-bottom: 2em;
      }
    }
  }
}
