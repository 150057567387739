#foundation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background-color: var(--color-bg-white);

  #container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 68em;
  }

  #bgComp1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 28em;
    z-index: 2;
  }

  #bgComp2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 28em;
    z-index: 2;
  }

  #bgComp3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 28em;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  #foundation {
    #container {
      #bgComp1 {
        left: 0em;
        top: 2em;
        width: 12em;
      }

      #bgComp2 {
        bottom: 15em;
        width: 12em;
      }
    }
  }
}
