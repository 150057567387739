#Foundation {
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-top: 3em;
  width: 80em;
  z-index: 10;

  #Container1 {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2em;

    >p {
      font-weight: bold;
      font-size: 1.4em;
      width: 26em;

      a {
        color: var(--color-bg-grey);
      }
    }

    >h1 {
      margin-top: 1em;
      color: var(--color-MR-purple);
      border-bottom: 3px solid var(--color-MR-pink-opaqueCC);
      width: fit-content;
      font-size: 2.5em;
      font-weight: 450;
    }
  }

  #Container2 {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 50%;

    >img {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  #Foundation {
    width: 60em;

    #Container1 {
      >h1 {
        font-size: 2em;
      }

      >p {
        font-size: 1.2em;
      }
    }
  }
}

@media (max-width: 900px) {
  #Foundation {
    width: 55em;

    #Container1 {
      >p {
        font-size: 1em;
      }

      >h1 {
        font-size: 2em;
      }
    }
  }
}

@media (max-width: 768px) {
  #Foundation {
    width: 100%;
    margin-top: 2em;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin-bottom: 3em;
    #Container1 {
      width: 99%;
      height: fit-content;
      padding: 0;

      >h1 {
        font-size: 2.5em;
        font-weight: 700;
        max-width: 90%;
        margin: 0 5%;
        margin-bottom: 0.5em;
      }

      >p {
        font-size: 1.5em;
        font-weight: 700;
        margin: 0 5%;
        max-width: 90%;
      }
    }

    #Container2 {
      width: 100%;

      >img {
        margin-bottom: 2em;
        width: 80%;
        height: 80vw;
        object-fit: cover;
        object-position: top;
        border-radius: 12px;
      }
    }
  }
}