// Wrapper used specifically for gradient buttons
.containerWrapper {
  padding: 4px; // Border
  width: fit-content;
  height: fit-content;
  background: var(--gradient-purplePinkBlue);
  border-radius: 2.7rem;
  overflow: visible;

  &.minWidth {
    min-width: 15em;
  }
}

.container {
  button {
    width: fit-content;
    height: fit-content;
    color: var(--color-MR-purple);
    background-color: white;
    border: solid var(--color-MR-purple);
    filter: drop-shadow(0px 4px 4px var(--color-shadow-black-1));
    border-radius: 2.7rem;
    padding: 0.4em 2.5em;
    font-size: 1.5em;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s;

    // Styles for different button types
    &.purple {
      color: var(--color-MR-purple);
      background-color: white;
      border: solid var(--color-MR-purple);
    }

    &.blue {
      color: var(--color-MR-blue);
      background-color: white;
      border: solid var(--color-MR-blue);
    }

    &.navy {
      color: var(--color-MR-navy);
      border: 4px solid var(--color-MR-navy);
    }

    &.gradient {
      color: var(--color-MR-purple);
      border: none;
    }

    // Modifier classes for button width
    &.minWidth {
      min-width: 15em;
    }

    &.fullWidth {
      width: 100%;
    }
  }
}

// Non-mobile styles
@media (min-width: 769px) {
  // Generic Hover effect for ALL buttons
  .container {
    button {
      &:hover {
        background-color: var(--color-bg-purpleBlend);
        color: black;
      }

      &.purple:hover {
        background: var(--color-MR-purple);
        color: var(--color-bg-white);
      }

      &.blue:hover {
        background-color: var(--color-MR-blue);
        color: var(--color-bg-white);
      }

      &.navy:hover {
        background-color: var(--color-MR-navy-opaque1A);
        color: black;
      }

      &.gradient:hover {
        background-color: var(--gradient-purplePinkBlue);
        color: white;
      }
    }
  }
}

@media (max-width: 1060px) {
  .container {
    button {
      min-width: 12em;
      font-size: 1.2em;
      padding: 0.5em 1.5em;
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: fit-content;
    button {
      min-width: 12em;
      font-size: 1.8em;
      padding: 0.5em 1.5em;
    }
  }
}