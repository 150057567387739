@font-face {
  font-family: 'Azo Sans';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 900;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-BlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 700;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 400;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 300;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 500;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/AzoSans/AzoSans-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Azo Sans';
  font-weight: 100;
  font-style: italic;
  src: url('~assets/fonts/AzoSans/AzoSans-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: italic;
  src: url('~assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: italic;
  src: url('~assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  src: url('~assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: italic;
  src: url('~assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url('~assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 100;
  font-style: italic;
  src: url('~assets/fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
}
