#foundation {
  display: flex;
  justify-content: center;
  background-color: var(--color-bg-offWhite);
  width: 100%;
  .container {
    display: flex;
    width: 81em;
    > img {
      width: 5em;
      margin: 0 2em;
      padding: 0;
      height: fit-content;
    }
    .innerContainer {
      padding: 2em 0;
      width: 75%;
      > h2 {
        font-size: 2.25em;
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        margin-bottom: 0.5em;
        margin-top: 1em;
      }
      > h3 {
        font-size: 1.5em;
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        margin-bottom: 0.5em;
        margin-top: 1em;
      }
      .links {
        color: blue;
      }
      a {
        color: black;
      }
      .boxs {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        img{
          align-self: start;
        }
      }
      >p{
        margin-bottom: 1em;
      }
      .roleData2Text{
        margin-bottom: 1em;
      }
    }
  }
}

@media (max-width: 768px) {
  #foundation {
    .container {
      width: 81%;
      > img {
        position: absolute;
        left: 0;
        margin: 0;
        margin-left: 1%;
        width: 8%;
      }
      .innerContainer {
        margin: 0;
        width: 100%;
        > h2 {
          font-size: 1.5em;
        }
        > h3 {
          font-size: 1.2em;
        }
      }
    }
  }
}
