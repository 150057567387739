.container {
  display: flex;
  justify-content: center;
  height: 34em;
  width: 100%;
  z-index: 0;
  height: fit-content;
  > div {
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 3em;
    background-color: var(--color-bg-white);
    box-shadow: 0 4px 8px var(--gradient-shadow-transparent-black);
    border-radius: 12px;
    width: 70em;
    margin-top: 3em;
    margin-bottom: 1.5em;
  }
  > .bgImg1 {
    position: absolute;
    top: 75em;
    left: -4em;
    height: 35em;
    transform: rotate(-134deg);
    z-index: 1;
  }
  .bgImg2 {
    position: absolute;
    right: 0;
    width: 18em;
  }
  .innerContainer {
    display: flex;
    flex-direction: column;
    margin-left: 2.5em;
    height: 100%;
    width: auto;
    justify-content: center;
    > h2 {
      font-size: 1.4em;
      color: var(--color-MR-purple);
      border-bottom: 2px solid var(--color-MR-pink);
      font-size: 2em;
      font-weight: 500;
      width: fit-content;
    }
    .p1 {
      margin: 0;
      margin-top: 1em;
    }
    .rolesContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      .roles {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        font-size: 1.1em;
        color: var(--color-MR-navy);
        height: 2em;
        img {
          width: 3em;
          transition: width .3s;
        }
        div{
          transition: color .3s;
        }
        :hover{
          cursor: pointer;
        }
      }
      .roles:hover img{
          width: 2.5em;
        }
        .roles:hover div{
          color: var(--color-MR-pink);
        }
    }
    .buttonContainer {
      display: flex;
      align-items: center;
      height: fit-content;
      width: fit-content;
      align-self: center;
      margin-top: 1em;
    }
  }
  .GroupWorkImg {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .container{
    > div{
      margin: 0 5em;
    }
  }
}
@media (max-width: 768px) {
  .container {
    justify-content: center;
    > .bgImg1 {
      display: block;
      height: 30vw;
    }
    .bgImg2 {
      bottom: 0;
    }
    > div {
      padding: 0;
      padding-top: 3em;
      width: 90%;
      height: fit-content;
      flex-direction: row;
      flex-wrap: wrap-reverse;
    }
    .innerContainer {
      margin: 0;
      margin-top: 1em;
      width: 100%;
      > h2 {
        font-size: 1.5em;
      }
      > p {
        font-size: 1em;
      }
      .rolesContainer {
        .roles {
          font-size: 1.2em;
        }
      }
      .buttonContainer {
        width: 100%;
        margin: 0;
        margin: 2em 0;
        justify-content: center;
      }
    }
  }
}
