#foundation {
  display: flex;
  flex-direction: column;
  align-items: center;

  #container {
    width: 80em;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    padding: 2em 0;
    border-radius: 12px;
    background-color: var(--color-bg-white);
    box-shadow: 2px 1px 3px 3px var(--gradient-fadedRose);
    z-index: 3;
    margin-bottom: 3em;

    > h2 {
      color: var(--color-MR-purple);
      border-bottom: 3px solid var(--color-MR-pink);
      width: fit-content;
      font-size: 2.5em;
      font-weight: 400;
    }

    > p {
      font-weight: bold;
      font-size: 1.4em;
    }

    #innerContainer {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;

      .boxs {
        margin: 0.8em 0;
        display: flex;
        width: 45%;
        height: 16em;
        border-radius: 12px;
        box-shadow: 2px 1px 3px 3px var(--gradient-fadedRose);
        padding: 1em;

        .boxsContainer1 > img {
          width: 8em;
          margin-right: 2em;
        }

        .boxsContainer2 {
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            font-size: 1em;
          }
          .title {
            border-bottom: 2px solid var(--color-MR-pink);
            width: fit-content;
            font-size: 1.6em;
            font-weight: 600;
            text-decoration: none;
            > h3 {
              color: var(--color-MR-blue);
              transition: color 0.3s;
            }
          }
          .title:hover {
            > h3 {
              color: var(--color-MR-blue-opaqueCC);
            }
          }

          .valuesContainer {
            margin-top: 1em;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 20em;
            height: 5em;
            font-weight: 700;
            align-content: flex-start;

            > div {
              width: 10em;
              display: flex;
              align-items: center;
              height: fit-content;
              padding: 0;
              font-size: 1em;
            }
          }

          .TechRoleBoxButtonContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            height: 3rem;
            width: 100%;
            position: relative;
            margin-top: auto;
            text-decoration: none;
            text-align: center;

            > div {
              color: var(--color-MR-purple);
              transition: color 0.5s;
              font-size: 1.5em;
            }

            > img {
              transition: width 0.3s;
              width: 3em;
            }
          }

          .TechRoleBoxButtonContainer:hover {
            cursor: pointer;

            > div {
              cursor: pointer;
              color: var(--color-text-hover-dark-violet);
            }

            > img {
              width: 2.5em;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    #container {
      width: 60em;

      > h2 {
        font-size: 2em;
      }

      > p {
        font-size: 1.2em;
      }

      #innerContainer {
        width: 100%;

        .boxs {
          margin: 0.3em 0;
          width: 45%;
          height: 14em;
          padding: 1em;

          .boxsContainer1 > img {
            width: 8em;
            margin-right: 2em;
          }

          .boxsContainer2 {
            display: flex;
            flex-direction: column;

            p {
              margin: 0;
              font-size: 0.8em;
              height: fit-content;
              width: calc(100% - 40px);
            }

            .title {
              font-size: 1.3em;
            }

            .valuesContainer {
              margin-top: 1em;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 20em;
              height: 5em;
              font-weight: 700;
              align-content: flex-start;

              > div {
                width: 10em;
                display: flex;
                align-items: center;
                height: fit-content;
                padding: 0;
                font-size: 0.8em;
              }
            }

            .TechRoleBoxButtonContainer {
              width: calc(100% - 30px);
              margin: 0;

              > div {
                color: var(--color-MR-purple);
                transition: color 0.5s;
              }

              > img {
                transition: width 0.3s;
              }
            }

            .TechRoleBoxButtonContainer:hover {
              > img {
                width: 2em;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    #container {
      width: 55em;

      > h2 {
        color: var(--color-MR-purple);
        border-bottom: 3px solid var(--color-MR-pink);
        width: fit-content;
        font-size: 1.5em;
        font-weight: 400;
      }

      > p {
        font-weight: bold;
        font-size: 1em;
      }

      #innerContainer {
        width: 98%;

        .boxs {
          margin: 0.3em 0;
          width: 45%;
          height: 12em;

          .boxsContainer1 > img {
            width: 6em;
            margin-right: 2em;
          }

          .boxsContainer2 {
            display: flex;
            flex-direction: column;

            p {
              margin: 0;
              font-size: 0.6em;
              width: calc(100% - 70px);
            }

            .title {
              font-size: 1.3em;
            }

            .valuesContainer {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 20em;
              height: 4em;
              align-content: flex-start;

              > div {
                width: 10em;
                display: flex;
                align-items: center;
                height: fit-content;
                padding: 0;
                font-size: 0.8em;

                > img {
                  margin-right: 0.5em;
                  width: 1em;
                }
              }
            }

            .TechRoleBoxButtonContainer {
              width: calc(100% - 70px);
              margin: 0;

              > div {
                font-size: 0.8em;
              }

              > img {
                width: 2em;
                transition: width 0.3s;
              }
            }

            .TechRoleBoxButtonContainer:hover {
              cursor: pointer;

              > div {
                cursor: pointer;
                color: var(--color-text-hover-dark-violet);
              }

              > img {
                width: 1.5em;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    #container {
      width: 100%;
      margin: 1em;

      h2 {
        font-size: 1.5em;
        font-weight: 500;
      }

      > p {
        margin-top: 0.5em;
        padding: 0 1em;
        font-size: 1.2em;
      }

      #innerContainer {
        flex-direction: column;
        align-items: center;

        .boxs {
          flex-direction: column;
          align-items: center;
          padding: 3em;
          margin: 0 2em;
          width: auto;
          margin-top: 2em;
          height: fit-content;
          .boxsContainer1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          .boxsContainer1 > img {
            width: 80%;
            margin: 0;
          }

          .boxsContainer2 {
            display: flex;
            flex-direction: column;

            .title {
              font-size: 1.5em;
              align-self: center;
              margin-top: 0.5em;
            }

            .valuesContainer {
              flex-direction: column;
              width: fit-content;
              height: fit-content;
              margin-bottom: 1em;
              align-self: center;

              > div {
                width: fit-content;
                font-size: 1.2em;
              }
            }

            p {
              font-size: 1em;
              width: 100%;
              text-align: center;
            }

            .TechRoleBoxButtonContainer {
              width: 100%;
              margin-top: 1em;

              > div {
                font-size: 1.5em;
              }

              > img {
                font-size: 1.2em;
              }
            }
          }
        }
      }
    }
  }
}
