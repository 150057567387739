.container {
  height: 100%;
  width: 90%;

  p {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5em;
    width: 100%;
    margin: 0;
  }
}
