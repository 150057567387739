.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3em;
  height: 100%;
  width: 92%;
  min-height: 22vh;
  border-radius: 0.5rem;
  border: 2px solid var(--color-MR-blue-opaque33);
  padding: 2em 0.5em;

  label:hover {
    border: inherit;
    font-weight: inherit;
  }

  .errorMessage {
    color: var(--color-MR-pink);
    font-size: 1em;
    margin-top: 0.5em;
    margin-left: 1em;
  }

  .thankYouMessage {
    width: 100%;
    h2 {
      color: var(--color-MR-blue);
    }
    p {
      text-align: center;
      width: 100%;
    }
  }

  .emailUs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin-right: 1em;

    h3 {
      color: var(--color-MR-purple);
      font-weight: bold;
      font-size: 1.5em;
      margin-left: 0.5em;
    }

    input[type='email'] {
      width: 100%;
      font-size: 1em;
      padding: 0.8em 1em;
      border-radius: 2.45rem;
    }

    // input[type='email']:hover {
    //   border: 1px solid black;
    // }

    .contactInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .contactConfirm {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--color-MR-purple);

      h3 {
        margin: 1em 0;
        margin-right: 1em;
        margin-left: 0.5em;
      }

      input[type='checkbox'] {
        opacity: 0;
        position: absolute;
      }

      .customCheckbox {
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        background: white;
        border: 3px solid var(--color-MR-purple);
        border-radius: 4px;
        position: relative;
        cursor: pointer;
      }

      input[type='checkbox']:checked + .customCheckbox::after {
        content: '';
        background-image: url('../../../../assets/icons/PurpleTick.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 4px;
        left: 2px;
        right: 2px;
        bottom: 0;
      }

      p {
        font-weight: 700;
        margin: 0;
        margin-right: 1em;
        width: fit-content;
      }
    }

    #inputContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 3em;

      .inputBoxs1 {
        width: 22em;
        margin-right: 3em;
        margin-top: 2em;

        > label h4 {
          margin: 0;
          padding: 0;
          color: var(--color-MR-purple);
          font-weight: 300;
          font-size: 1.2em;
          margin-bottom: 0.2em;
          margin-left: 0.5em;
        }

        .FormControlDrop {
          width: 100%;
        }

        .inputFields {
          height: auto;
          width: 100%;
          font-size: 1.2em;
          border-radius: 2.45rem;
          box-shadow: 0px 3px 14px -3px var(--color-shadow-black-1);

          .MuiSelect-select {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .inputBoxs2 {
        width: 47em;
        margin: 1em 0;
        margin-top: 2em;

        > label h4 {
          margin: 0;
          padding: 0;
          color: var(--color-MR-purple);
          font-weight: 300;
          font-size: 1.2em;
          margin-bottom: 0.2em;
          margin-left: 0.5em;
        }

        .inputFields {
          height: auto;
          width: 100%;
          font-size: 1.2em;
          border-radius: 2.45rem;
          box-shadow: 0px 3px 14px -3px var(--color-shadow-black-1);

          .MuiSelect-select {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  img {
    height: 80%;
    max-height: 9em;
    margin-left: 1.5em;
  }

  h3 {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 2em 1em;
    margin-top: 2em;
    img {
      margin-left: 0em;
    }

    .logo {
      margin-bottom: 1.5em;
    }

    .emailUs {
      width: 100%;
      margin: 0;
      align-items: center;
      h3 {
        font-size: 1.3em;
      }

      .contactConfirm {
        h3 {
          width: 80%;
        }
      }

      .contactInfo {
        padding: 0;
        display: flex;
        flex-direction: column;
        row-gap: 1.5em;
        width: 100%;
        align-items: center;

        input {
          margin-right: 0;
          padding: 0.8em 1em;
        }
      }

      #inputContainer {
        margin: 0;
        padding: 0;
        margin-bottom: 1em;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputBoxs1,
        .inputBoxs2 {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 1em;
        }
      }
    }

    #buttonSend > div > button {
      min-width: 10em;
    }
  }
}
