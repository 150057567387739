.container {
  // border: solid blue;
  width: 50%;
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    height: fit-content;
  }
}
