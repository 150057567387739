#foundation {
  width: 100%;
  background-color: var(--color-bg-offWhite);
  .container {
    display: flex;
    justify-content: left;
    margin-right: auto;
    margin-left: auto;
    width: 81em;
    >img {
      margin: 0 2em;
      width: 5em;
      height: fit-content;
      padding-top: 0;
      margin-top: 0;
    }
    .innerContainer {
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding-left: 0;
    }
  }
}
@media (max-width: 1160px) {
  #foundation {
    .container {
      width: 70em;
      .innerContainer {
        margin-right: 8em;
      }
    }
  }
}
@media (max-width: 1060px) {
  #foundation {
    .container {
      display: flex;
      width: 100%;
      background-color: var(--color-bg-offWhite);
      justify-content: center;
      .innerContainer {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 100%;
    .container {
      display: flex;
      width: 90%;
      background-color: var(--color-bg-offWhite);
      > img {
        position: absolute;
        left: 0;
        margin: 0;
        margin-left: 1%;
        width: 8%;
      }
      .innerContainer {
        width: 90%;
        padding: 0;
      }
    }
  }
}
