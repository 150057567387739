@import 'fonts';
@import 'variables';

html {
  font-size: 16px;
}

html,
body, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  color: var(--color-bg-grey);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
p,
ul,
ol,
table,
input,
textarea,
button {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

h1 {
  font-family: 'Azo Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

h2 {
  font-family: 'Azo Sans', Arial, sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
}

h3 {
  font-family: 'Azo Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

h4 {
  font-family: 'Azo Sans', Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
}

h5,
h6 {
  font-family: 'Azo Sans', Arial, sans-serif;
  font-weight: 500;
}

@media (max-width: 1300px) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}