.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  height: 100%;
  min-height: 87vh;

  h1 {
    font-size: 2.25rem;
    font-weight: 500;
    margin-top: 0.8em;
    color: var(--color-MR-purple);
  }
}

@media (max-width: 768px) {
  .container {
    width: 90vw;

    h1 {
      font-size: 2rem;
      font-weight: 500;
      margin-top: 0.6em;
    }
  }
}