.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
  width: 80%;
  min-height: 20em;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;

  .customList {
    list-style: none;
    padding-left: 0;
    width: 100%;

    span {
      font-size: 1.125em;
    }

    li {
      display: flex;
      align-items: flex-start;
      column-gap: 0.5em;

      .bulletIcon {
        flex-shrink: 0;
        flex-basis: 2em;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .arrowIcon {
    display: inline;
    width: 2em;
    margin-left: 1.5em;
    transition: width 0.5s;
  }
}

.questionText {
  margin-left: 1em;
  transition: opacity 0.2s ease-out;
  opacity: 1;
}

h3 {
  color: var(--color-MR-purple);
  font-size: 1.25em;
  font-weight: 600;
}
.knowMore,.findOutMore{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-MR-purple);
  font-size: 1.25em;
  font-weight: 600;
  transition: font-weight 0.1s ease-in-out;
  transition: color 0.2s;
}
.findOutMoreContainer{
  text-decoration: none;
  
}
.knowMore:hover,.findOutMore:hover {
  cursor: pointer;
  color: var(--color-text-hover-dark-violet);
  .arrowIcon {
    width: 1.5em;
  }
}

.fadeOut {
  opacity: 0;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}
