.container {
  display: flex;
  width: 50%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;

  .progress {
    position: relative;
    width: 100%;
    border-radius: 4px;
    height: 5px;
    background-color: var(--color-bg-lightGrey2);
  }

  .progressBar {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
    background-color: var(--color-bg-purpleBlend);
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 85%;
    margin-bottom: 1.5em;
  }
}
