#foundation {
  margin-top: 2em;
  margin-bottom: 4em;
  #container {
    width: 70em;
    height: fit-content;
    box-shadow: 2px 1px 3px 3px var(--gradient-fadedRose);
    border-radius: 12px;
    background-color: var(--color-bg-white);
    padding: 3em 3em;
    > h1 {
      color: var(--color-MR-purple);
      border-bottom: 2px solid var(--color-MR-pink);
      width: fit-content;
      margin-bottom: 1em;
      font-size: 3em;
      font-weight: 500;
    }
    #innerContainer {
      display: flex;
      flex-direction: row;
      img {
        border-radius: 12px;
        max-width: 35%;
        height: 20em;
      }
      #descriptionContainer {
        width: auto;
        font-size: 1.125em;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 4em;
        .subHeader {
          font-weight: 700;
          font-size: 1.5em;
          width: auto;
        }
        > p {
          width: 75%;
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  #foundation {
    #container {
      width: 65em;

      #innerContainer {
        #descriptionContainer {
          .subHeader {
            font-size: 1.25em;
            width: 100%;
          }
          > p {
            font-size: 1em;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 1060px) {
  #foundation {
    #container {
      width: 56em;
      > h1 {
        font-size: 2em;
      }
      #innerContainer {
        #descriptionContainer {
          .subHeader {
            font-size: 1.25em;
            width: 100%;
          }
          > p {
            font-size: 1em;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 860px) {
  #foundation {
    #container {
      width: 46em;
      height: fit-content;
      padding-bottom: 4em;
      #innerContainer {
        #descriptionContainer {
          .subHeader {
            font-size: 1.25em;
            width: 90%;
          }
          > p {
            font-size: 1em;
            width: 90%;
          }
        }
      }
    }
  }
}
@media (max-width: 760px) {
  #foundation {
    display: flex;
    justify-content: center;
    #container {
      width: 80%;
      padding: 2em;
      height: fit-content;
      #innerContainer {
        display: flex;
        flex-direction: column;
        img {
          max-width: 100%;
          height: fit-content;
        }
        #descriptionContainer {
          padding: 0;
          max-width: 100%;
          .subHeader {
            font-size: 1.5em;
            width: 90%;
          }
          > p {
            width: 90%;
          }
        }
      }
    }
  }
}
