.container {
  display: flex;
  justify-content: center;

  span {
    font-size: 1.125em;
    font-weight: 500;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

@media (max-width: 768px) {
  .container {
    span {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
}
