.containerWrapper {
  height: 100%;
  width: 80vw;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  padding: 4px; // Border
  background: var(--gradient-purplePinkBlue);
  border-radius: 0.5rem;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  min-height: 80vh;
  background: white;
  border-radius: calc(0.5rem - 4px); //Reduce border-radius to fit within wrapper
  padding: 4em;

  h2.title {
    font-size: 2.24em;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
    color: var(--color-MR-purple);
  }

  h3 {
    color: black;
  }
}

@media (max-width: 768px) {
  .containerWrapper {
    width: 90%;
  }

  .container {
    padding: 1em;
  }
}