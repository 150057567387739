#foundation {
  width: 73em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
  > img {
    position: absolute;
    z-index: -2;
    width: 100%;
  }
  .backgroundImgCover {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-home-FirstBgImgShade);
    pointer-events: none;
    z-index: -1;
  }
  #container1 {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    #mobileContainer2innerContainer1 {
      display: none;
      h2 {
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        font-size: 1em;
        font-weight: 500;
      }
    }
    > h1 {
      display: flex;
      font-size: 3.5em;
      line-height: 1em;
      text-align: center;
      br {
        display: none;
      }
      div {
        color: var(--color-MR-purple);
        margin-left: 0.5em;
      }
    }
    > p {
      font-size: 3em;
      margin: 0;
    }
    > iframe {
      margin-top: 3em;
      width: 47.3em;
      height: 25em;
      border-radius: 12px;
      z-index: 2;
    }
    .peerWorkShadowImg {
      margin-top: 0;
      position: relative;
      top: -10px;
      width: 52em;
      height: 3em;
      z-index: 1;
    }
  }
  #container2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    #button1 {
      margin: 0 2em;
    }
    #container2innerContainer1 {
      width: 31em;
      margin-top: 2em;
      h2 {
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        font-size: 2.2em;
        font-weight: 500;
      }
      p {
        font-size: 1.4em;
        margin: 0;
        margin-top: 0.5em;
      }
    }
    #container2innerContainer2 {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: flex-end;
      padding-bottom: 0.5em;
      width: calc(100% - 31em);
    }
  }
}
@media (max-width: 1060px) {
  #foundation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3em;

    > img {
      position: absolute;
      z-index: -2;
      width: 100%;
    }
    .backgroundImgCover {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--gradient-home-FirstBgImgShade);
      pointer-events: none;
      z-index: -1;
    }
    #container1 {
      margin-top: 4em;
      display: flex;
      flex-direction: column;
      align-items: center;
      #mobileContainer2innerContainer1 {
        display: none;
      }

      > h1 {
        display: flex;
        font-size: 3.5em;
        line-height: 1em;
        text-align: center;
        br {
          display: none;
        }
        div {
          color: var(--color-MR-purple);
          margin-left: 0.5em;
        }
      }
      > p {
        font-size: 3em;
        margin: 0;
      }
      > iframe {
        margin-top: 3em;
        width: 40em;
      }
      .peerWorkShadowImg {
        margin-top: 0;
        position: relative;
        top: -10px;
        width: 46em;
      }
    }
    #container2 {
      width: 54em;
      display: flex;
      #container2innerContainer1 {
        width: 22em;
        margin-top: 2em;
        h2 {
          color: var(--color-MR-purple);
          font-size: 2em;
          font-weight: 500;
        }
        p {
          font-size: 1em;
          margin: 0;
          margin-top: 0.5em;
        }
      }
      #container2innerContainer2 {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: flex-end;
        padding-bottom: 0.5em;
      }
    }
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 100%;
    #container1 {
      margin-top: 0;
      > h1 {
        flex-direction: column;
        font-size: 2.2em;
        text-align: center;
        line-height: 1.2em;
        br {
          display: block;
        }
      }
      > p {
        margin-top: 0.5em;
        margin-bottom: 1.5em;
        font-size: 1.5em;
        font-weight: 300;
      }
      > iframe {
        margin-top: 3em;
        width: 80%;
      }
      .peerWorkShadowImg {
        margin-top: 0;
        position: relative;
        top: -10px;
        width: 90%;
      }
      #mobileContainer2innerContainer1 {
        display: block;
        width: auto;
        padding: 0 10%;
        h2 {
          font-size: 1.5em;
          color: var(--color-MR-purple);
          text-decoration: none;
        }
        p {
          font-size: 1.2em;
          margin: 0;
          margin-top: 0.5em;
        }
      }
    }
    #container2 {
      flex-direction: column;
      width: 100%;
      align-items: center;
      #container2innerContainer1 {
        display: none;
      }
      #container2innerContainer2 {
        flex-direction: column;
        align-items: center;
        width: 80vw;
        #button1 {
          margin-bottom: 2em;
        }
      }
    }
  }
}
