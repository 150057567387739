.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  label {
    border-radius: 3.125rem;
    border: 1px solid var(--color-bg-lightGrey);
    padding: 1em 1em;
    margin-bottom: 1em;
    font-size: 1em;
    cursor: pointer;
    width: 50%;
    background-color: transparent;
    text-align: left;
  }

  .optionSelected {
    border: 1px solid transparent;
    background-color: var(--color-MR-purple);
    color: white;
    font-size: 1em;
    font-weight: 700;
  }
}

.realCheckbox {
  display: none;
}

.customCheckbox {
  position: relative;
  padding-left: 30px;

  /* Space for custom checkbox */
  .tick {
    position: absolute;
    left: -2.2em;
    width: 1em;
    height: 1em;
    bottom: 0.9em;
  }
}

.customCheckbox::before {
  content: '';
  position: absolute;
  left: -3em;
  top: 0.5em;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  border: 1px solid var(--color-bg-lightGrey2);
}

.realCheckbox:checked+.customCheckbox::after {
  // content: "\2713";  /* Unicode check mark */
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  left: -1.65em;
  top: -0.1em;
  font-size: 1.5em;
  color: var(--color-checkbox-grey);
}

.answerText {
  transition: opacity 0.2s ease-out;
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}


// Non-mobile styles
@media (min-width: 769px) {
  label:hover {
    border: 1px solid purple;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .container {
    button {
      padding: 1em;
      min-width: 80%;
      max-width: 95%;
      text-align: center;
    }

    label {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}