#foundation {
  width: 60em;
  padding-bottom: 4em;
  img {
    width: 90%;
    height: 20em;
    margin: 0;
    padding: 0;
    border-radius: 12px;
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    >p{
      margin: .5em 0;
    }
    a{
      color: black;
    }
    h3 {
      font-size: 1.5em;
      color: var(--color-MR-purple);
      border-bottom: 2px solid var(--color-MR-pink);
      width: fit-content;
    }
  }
}
@media (max-width: 970px) {
  #foundation {
    width: fit-content;
    padding-right: 1em;
  }
}
@media (max-width: 860px) {
  #foundation {
    margin-left: 1em;
    .container {
      h3 {
        width: fit-content;
      }
    }
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 100%;
    margin: 0;
    img{
      width: 100%;
      height: fit-content;
    }
    .container {
      h3 {
        margin-top: 1em;
        width: fit-content;
        font-size: 1.2em;
      }
    }
  }
}
