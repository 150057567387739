.container {
  display: flex;

  button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    background-color: var(--color-MR-purple);
    border: none;
    border-radius: 3.125rem;
    padding: 0.5em 1em;
    font-size: 1em;
    min-width: 6.5em;
    cursor: pointer;

    .Arrow {
      height: 1em;
    }
  }
}

// Non-mobile styles
@media (min-width: 769px) {
  button:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    color: white;
  }
}

@media (max-width: 768px) {
  .container {
    margin-right: 0.5em;

    button {
      padding: 1em;
    }
  }
}