#foundation {
  background-color: #fff;
  border-radius: 12px;
  padding: 3em 3em;
  width: 70em;
  margin-top: 4em;
  margin-bottom: 4em;
  box-shadow: 2px 1px 3px 3px var(--gradient-fadedRose);
  .container {
    display: flex;
    justify-content: left;
    width: 100%;
    .innerContainer {
      > h2 {
        font-size: 2.25em;
        color: var(--color-MR-purple);
        border-bottom: 2px solid var(--color-MR-pink);
        width: fit-content;
        margin-bottom: 0.5em;
      }
      >p{
        margin: .5em 0;
      }
      .boxs {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        color: var(--color-MR-blue);
        > img {
          align-self: flex-start;
          margin-right: 0.5em;
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  #foundation {
    width: 60em;
  }
}
@media (max-width: 1000px) {
  #foundation {
    width: auto;
    margin: 3em;
  }
}
@media (max-width: 768px) {
  #foundation {
    width: 80%;
    margin: 2em 5%;
    padding: 4.5%;
    .container {
      justify-content: left;
      > img {
        position: absolute;
        left: 0;
        margin: 0;
        margin-left: 1%;
        width: 8%;
      }
      .innerContainer {
        margin: 0;
        width: 100%;
        > h2 {
          font-size: 1.5em;
        }
      }
    }
  }
}
