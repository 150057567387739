.container {
  display: flex;
  justify-content: center;
  height: 30em;
  width: 100%;
  background-color: var(--color-bg-offWhite);
  z-index: 1;
  height: fit-content;
  padding: 3em 0;
  > div {
    padding: 3em;
  }
  > img {
    height: 15em;
  }
  .innerContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > h2 {
      color: var(--color-MR-purple);
      border-bottom: 2px solid var(--color-MR-pink);
      width: fit-content;
      font-size: 2.4em;
      font-weight: 500;
      margin-bottom: .5em;
    }
    p{
      margin-right: 1em;
      font-size: 1.2em;
      margin-bottom: 3em;
    }
    > div {
      display: flex;
      align-items: center;
      height: fit-content;
      width: fit-content;
    }
  }
  .girlWithPhoneImg {
    width: 86%;
  }
}
@media (max-width: 1060px) {
  .container {
    display: flex;
    justify-content: center;
    height: 30em;
    width: 100%;
    background-color: var(--color-bg-offWhite);
    z-index: 1;
    height: fit-content;
    > div {
      padding: 3em;
    }
    > img {
      height: 15em;
    }
    .innerContainer {
      > h2 {
        font-size: 1.4em;
        color: var(--color-MR-purple);
        font-size: 2em;
        font-weight: 500;
      }
      .p1 {
        margin: 0;
        margin-top: 0.5em;
      }
      > div {
        display: flex;
        align-items: center;
        height: fit-content;
        width: fit-content;
      }
    }
    .girlWithPhoneImg {
      width: 100%;
    }
  }
}
@media (max-width: 950px) {
  .container {
    > div {
      padding: 0;
    }
    > img {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .container {
    justify-content: left;
    > img {
      display: block;
      height: 30vw;
    }
    > div {
      padding: 0;
      padding-top: 3em;
    }
    .innerContainer {
      margin-top: 1em;
      width: 100%;
      > h2 {
        font-size: 1.5em;
      }
      > p {
        font-size: 1em;
      }
      > div {
        width: 100%;
        margin: 0;
      }
    }
  }
}
