.container1 {
  display: flex;
  flex-direction: row;
  width: 65em;
}
.container2 {
  display: flex;
  flex-direction: row;
  width: 70em;
}
@media (max-width: 1200px) {
  .container1 {
    width: auto;
  }
  .container2 {
    width: auto;
  }
}
@media (max-width: 1060px) {
  .container1 {
    width: 55em;
  }
  .container2 {
    width: 55em;
  }
}
@media (max-width: 950px) {
  .container1 {
    width: auto;
    margin: 0 2em;
  }
  .container2 {
    width: auto;
  }
}
@media (max-width: 768px) {
  .container1 {
    height: fit-content;
    flex-wrap: wrap;
    width: 80%;
    margin: 2em 0;
  }
  .container2 {
    height: fit-content;
    flex-wrap: wrap-reverse;
    width: 80%;
  }
}
