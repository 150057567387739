:root {
  --color-MR-blue: #1B75BB;
  --color-MR-navy: #0E0635;
  --color-MR-pink: #EB008D;
  --color-MR-purple: #6B2C90;

  --color-MR-pink-opaque99: #EB008D99;
  --color-MR-pink-opaqueCC: #EB008DCC;
  --color-MR-pink-opaque66: #EB008D66;
  --color-MR-pink-opaque33: #EB008D33;
  --color-MR-pink-opaque1A: #EB008D1A;
  --color-MR-pink-opaque0D: #EB008D0D;

  --color-MR-navy-opaqueCC: #0E0635CC;
  --color-MR-navy-opaque99: #0E063599;
  --color-MR-navy-opaque66: #0E063566;
  --color-MR-navy-opaque33: #0E063533;
  --color-MR-navy-opaque1A: #0E06351A;
  --color-MR-navy-opaque0D: #0E06350D;

  --color-MR-blue-opaqueCC: #1B75BBCC;
  --color-MR-blue-opaque99: #1B75BB99;
  --color-MR-blue-opaque66: #1B75BB66;
  --color-MR-blue-opaque33: #1B75BB33;
  --color-MR-blue-opaque1A: #1B75BB1A;
  --color-MR-blue-opaque0D: #1B75BB0D;

  --color-MR-purple-opaqueCC: #6B2C90CC;
  --color-MR-purple-opaque99: #6B2C9099;
  --color-MR-purple-opaque66: #6B2C9066;
  --color-MR-purple-opaque33: #6B2C9033;
  --color-MR-purple-opaque1A: #6B2C901A;
  --color-MR-purple-opaque0D: #6B2C900D;

  --color-bg-grey: #474647;
  --color-navSelectBorder-grey:#B3B3B3;
  --color-checkbox-grey: #59576B;
  --color-multi-choice-hover:#4D2068;
  --color-bg-white: #FFFFFF;
  --color-bg-offWhite: #FAFAFB;
  --color-bg-black: #000000;
  --color-bg-lightGrey: #F1F1F1;
  --color-bg-purpleBlend: #8956A6;
  --color-bg-lightGrey2: #D9D9D9;
  --color-MR-blue-opaque33: #1B75BB33;
  --color-text-hover-dark-violet: #9c44cf;

  --color-shadow-black-1:  rgba(0, 0, 0, 0.25);

  --gradient-bluePurplePink: linear-gradient(180deg, rgba(49, 97, 171, 0.5) 0%, rgba(107, 44, 144, 0.5) 65.62%, rgba(235, 0, 141, 0.5) 98.44%);
  --gradient-purplePinkBlue: linear-gradient(205.98deg, #6B2C90 16.55%, #EB008D 45.9%, #EB008D 56.36%, #1B75BB 92.22%);
  --gradient-pinkBlue: linear-gradient(185.31deg, #C50C88 19.64%, #3161AB 85.29%);
  --gradient-pinkBlue2: linear-gradient(174.62deg, #C50C88 45.7%, #3161AB 111.29%);
  --gradient-pinkBlue3: linear-gradient(232.61deg, #C50C88 13.27%, #3161AB 84.59%);
  --gradient-bluePurplePink2: linear-gradient(177.87deg, rgba(49, 97, 171, 0.5) 8%, rgba(107, 44, 144, 0.5) 52.16%, rgba(235, 0, 141, 0.5) 96.8%);
  --gradient-fadedRose: rgba(174, 161, 161, 0.2);
  --gradient-shadow-darkGrey: rgba(0, 0, 0, 0.4);
  --gradient-navSelectShadow-grey:rgba(0, 0, 0, 0.25);
  --gradient-404background:linear-gradient(0deg,#eb008d 0%,#2d64b0 29.01%,#6b2c90 61.2%,#0e0635 100%);
  --gradient-shadow-transparent-black:rgba(0, 0, 0, 0.1);
}



